import { Container, Grid, Icon } from "semantic-ui-react";
import "./Footer.css";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

let deferredPrompt;

window.addEventListener("beforeinstallprompt", (e) => {
  document.querySelector("#pwa").hidden = false;
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();

  if (!window.matchMedia("(display-mode: standalone)").matches) {
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  }
});

function Footer() {
  // const { t } = useTranslation();
  const location = window.location;

  const downloadPWA = (e) => {
    e.preventDefault();

    if (deferredPrompt) {
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          localStorage.setItem("pwaStatus", "accepted");
          console.log("User accepted the A2HS prompt");
        } else {
          localStorage.setItem("pwaStatus", "denied");
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt = null;
      });

      localStorage.setItem("pwaPrompt", true);
    }
  };

  return (
    <footer id="main-footer">
      <div id="top-footer" className="py-5">
        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={5}>
                <h3>Get in touch with us</h3>

                <p>
                  <Icon name="envelope" />
                  <a
                    href="mailto:contact@apluswealth.com"
                    className="letter-spacing"
                  >
                    contact@apluswealth.com
                  </a>
                </p>

                <p>
                  <Icon name="phone" />
                  <a href="tel:+1-888-461-6120" className="letter-spacing">
                    +1-888-461-6120
                  </a>
                </p>

                <p>
                  <Icon name="map marker alternate" />18 King St E #1400, Toronto, ON M5C 1C4
                </p>

                <div className="py-3 d-flex align-items-center justify-content-start">
                  <a
                    href="https://www.linkedin.com/company/apluswealth/"
                    target="_blank"
                    className="mr-2"
                  >
                    <Icon name="linkedin" size="large" color="grey" inverted />
                  </a>
                  <a
                    href="https://www.instagram.com/apluswealth/"
                    target="_blank"
                    className="mr-2"
                  >
                    <Icon name="instagram" size="large" color="grey" inverted />
                  </a>
                  <a
                    href="https://twitter.com/Apluswealth_Inc"
                    target="_blank"
                    className="mr-2"
                  >
                    <Icon name="twitter" size="large" color="grey" inverted />
                  </a>
                  <a
                    href="https://www.facebook.com/apluswealthcanada/"
                    target="_blank"
                    className="mr-2"
                  >
                    <Icon name="facebook" size="large" color="grey" inverted />
                  </a>
                </div>
              </Grid.Column>

              <Grid.Column width={3}>
                <h3>Get Insurance</h3>

                <p>
                  <Link to="/quote/life">Life Insurance</Link>
                </p>

                <p>
                  <Link to="/quote/CI">Critical Illness Insurance</Link>
                </p>

                <p>
                  <Link to="/quote/DI">Disability Insurance</Link>
                </p>
              </Grid.Column>

              <Grid.Column width={3}>
                <h3>About Our Company</h3>

                <p>
                  <a
                    href="https://www.apluswealth.com/about-apluswealth/"
                    target="_blank"
                  >
                    About Us
                  </a>
                </p>

                <p>
                  <a
                    href="https://www.apluswealth.com/contact-us/"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </p>
              </Grid.Column>

              {location.search.indexOf("ios") === -1 &&
                location.search.indexOf("android") === -1 && (
                  <Grid.Column width={5} className="download">
                    <h3>Get the app</h3>
                    <a
                      href="#"
                      id="pwa"
                      hidden
                      onClick={downloadPWA}
                      className="mr-2"
                    >
                      <img
                        src="/assets/img/app-buttons/pwa-sm.png"
                        alt="Get Covered PWA"
                        height="50px"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                      id="google-play"
                      className="mr-2"
                      target="_blank"
                    >
                      <img
                        src="/assets/img/app-buttons/google-play-sm.png"
                        alt="Get Covered Google Play"
                        height="50px"
                        width="167px"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/get-covered/id1567827832"
                      id="app-store"
                      className="mr-2"
                      target="_blank"
                    >
                      <img
                        src="/assets/img/app-buttons/app-store-sm.png"
                        alt="Get Covered App Store"
                        height="50px"
                        width="167px"
                        loading="lazy"
                      />
                    </a>
                  </Grid.Column>
                )}
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <div id="bottom-footer" className="py-3">
        <div className="text-center">
          2018-2021 © Copyright AplusWealth Inc.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
