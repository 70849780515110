import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Grid, Icon, Image, Menu } from "semantic-ui-react";
import { UserContext } from "../Layout";
import { default as config } from "../../constants/config.json";

import "./Header.css";
import { Link } from "react-router-dom";

import LazyLoad from "react-lazyload";

function Header() {
  const { t } = useTranslation();
  const { userData, userActions } = useContext(UserContext);

  // useEffect(() => {
  //   console.log(userData);
  // }, [userData]);

  const [visibleSidebar, setVisibleSidebar] = useState(false);
  function showSidebar(e) {
    e.preventDefault();
    setVisibleSidebar(true);
  }
  function hideSidebar(e) {
    setVisibleSidebar(false);
  }

  return (
    <>
      <header id="main-header" className="px-2">
        <Grid padded>
          <Grid.Row className="justify-content-between">
            <Grid.Column
              computer={3}
              mobile={16}
              tablet={4}
              className="d-flex align-items-center"
            >
              <a
                href="#"
                onClick={showSidebar}
                className="mr-1 toggle-sidebar d-block d-md-none"
              >
                <Icon name="bars" size="big" />
              </a>

              <Link to="/">
                <Image
                  src="./assets/img/logo.png"
                  alt={t("main.mainTitle")}
                  title={t("main.mainTitle")}
                  height="40px"
                  width="174px"
                  className="logo"
                  loading="lazy"
                />
              </Link>
            </Grid.Column>

            <Grid.Column
              computer={12}
              mobile={16}
              tablet={12}
              className="d-flex align-items-center justify-content-md-end justify-content-between mt-md-0 mt-3"
            >
              <a href="tel: +1-888-461-6120" className="mx-md-2 mr-2 text-white">
                <Icon
                  name="phone"
                  flipped="horizontally"
                  className="d-md-inline d-none"
                />
                +1-888-461-6120
              </a>

              <a href="mailto: Contact@AplusWealth.com" className="mx-md-3 mx-1 mr-md-4 text-white">
                <Icon name="envelope" className="d-md-inline d-none" />
                Contact@AplusWealth.com
              </a>

              {userData && (
                <Dropdown
                  className="d-none d-md-block"
                  direction="left"
                  text={
                    <div>
                      {userData.avatar && userData.avatar.length > 0 && (
                        <Image
                          loading="lazy"
                          src={`${
                            config[process.env.NODE_ENV].backendFileBaseUrl
                          }${userData.avatar}`}
                          avatar
                        />
                      )}
                      {!userData.avatar && userData.avatar.length === 0 && (
                        <Icon name="user alternate" />
                      )}
                      <span>{userData.name}</span>
                    </div>
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/panel/qoutes">
                        <Icon name="copy outline" className="mr-2" />{" "}
                        {t("panel.qoutes")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/panel/profile">
                        <Icon name="user circle" className="mr-2" />
                        {t("panel.profile")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/panel/news">
                        <Icon name="newspaper outline" className="mr-2" />
                        {t("panel.news")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/panel/calculators">
                        <Icon name="calculator" className="mr-2" />
                        {t("panel.calculators")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={userActions.logout}>
                      <Icon name="sign-out" className="mr-2" />
                      {t("main.logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {!userData && (
                <Button
                  basic
                  inverted
                  onClick={userActions.openUserModal}
                  className="d-none d-md-block"
                >
                  <Icon name="user" />
                  {t("main.login")}/{t("main.register")}
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </header>

      <LazyLoad once>
        <div id="main-sidebar" className={visibleSidebar ? "show" : ""}>
          <Menu vertical fluid onClick={hideSidebar}>
            <Menu.Item as={Link} to="/">
              <Link to="/">
                <Image
                  src="./assets/img/logo.png"
                  alt={t("main.mainTitle")}
                  title={t("main.mainTitle")}
                  height="40px"
                  width="174px"
                  className="logo"
                  loading="lazy"
                />
              </Link>
            </Menu.Item>

            {!userData && (
              <>
                <Menu.Item onClick={userActions.openUserModal}>
                  <span>
                    <Icon name="sign-in" className="mr-2" color="teal" />
                    {t("main.login")}
                  </span>
                </Menu.Item>
                <Menu.Item onClick={userActions.openUserModal}>
                  <span>
                    <Icon name="user plus" className="mr-2" color="teal" />
                    {t("main.register")}
                  </span>
                </Menu.Item>
              </>
            )}

            {userData && (
              <>
                <Menu.Item as={Link} to="/panel/qoutes">
                  <span>
                    <Icon name="copy outline" className="mr-2" color="teal" />
                    {t("panel.qoutes")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to="/panel/profile">
                  <span>
                    <Icon name="user circle" className="mr-2" color="teal" />
                    {t("panel.profile")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to="/panel/news">
                  <span>
                    <Icon
                      name="newspaper outline"
                      className="mr-2"
                      color="teal"
                    />
                    {t("panel.news")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to="/panel/calculators" key="calculators">
                  <span>
                    <Icon name="calculator" className="mr-2" color="teal" />
                    {t("panel.calculators")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} onClick={userActions.logout}>
                  <span>
                    <Icon name="sign-out" className="mr-2" color="teal" />
                    {t("main.logout")}
                  </span>
                </Menu.Item>
              </>
            )}
          </Menu>
        </div>
        <div
          id="sidebar-backdrop"
          className={visibleSidebar ? "show" : ""}
          onClick={hideSidebar}
        ></div>
      </LazyLoad>
    </>
  );
}

export default Header;
