import React, { Suspense, useEffect, useState } from "react";
import { Container, Grid } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./Home.css";
import Loading from "../../components/Loading/Loading";

const CompanySlider = React.lazy(() =>
  import("../../components/CompanySlider/CompanySlider")
);

//import CompanySlider from "../../components/CompanySlider/CompanySlider";

// import "../../styles/icon/svg-sprite.svg"

export default function Home() {
  const { t } = useTranslation();
  const [sliderLoad, setSliderLoad] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setSliderLoad(1);
    }, 2500);

    setTimeout(() => {
      var ajax = new XMLHttpRequest();
      ajax.open("GET", "/assets/img/home-icons.svg", true);
      ajax.onload = function (e) {
        var div = document.createElement("div");
        div.innerHTML = ajax.responseText;
        document.body.appendChild(div);
      };
      ajax.send();
    }, 1000);
  }, []);

  return (
    <Container as="main" className="py-4">
      <h1 className="text-center mb-5 mt-3">{t("main.getQoute")}</h1>

      <Grid className="mx-0">
        <Grid.Row className="section-wrapper insurance-type have-icon">
          <Grid.Column computer={4} tablet={5} mobile={8}>
            <NavLink
              to="/quote/life"
              className={`section`}
              activeClassName="active"
            >
              <label className="flex-column">
                <svg className="icon">
                  <use xlinkHref="#family" />
                </svg>
                {t("main.lifeInsurance")}
              </label>
              <input required type="radio" name="insuranceType" value="life" />
            </NavLink>
          </Grid.Column>

          <Grid.Column computer={4} tablet={5} mobile={8}>
            <NavLink
              to="/quote/CI"
              className={`section`}
              activeClassName="active"
            >
              <label>
                <svg className="icon">
                  <use xlinkHref="#medical-care" />
                </svg>
                {t("main.CIInsurance")}
              </label>
              <input required type="radio" name="insuranceType" value="CI" />
            </NavLink>
          </Grid.Column>

          <Grid.Column computer={4} tablet={5} mobile={8}>
            <NavLink
              to="/quote/DI"
              className={`section`}
              activeClassName="active"
            >
              <label>
                <svg className="icon">
                  <use xlinkHref="#total" />
                </svg>
                {t("main.DIInsurance")}
              </label>
              <input required type="radio" name="insuranceType" value="DI" />
            </NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Container fluid className="px-md-5">
        <Suspense fallback={<Loading />}>
          {sliderLoad > 0 && <CompanySlider />}
        </Suspense>
        {sliderLoad === 0 && (
          <div className="py-4">
            <Loading />
          </div>
        )}
      </Container>
    </Container>
  );
}
